//import './bootstrap';
import Alpine from "alpinejs";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import itLocale from '@fullcalendar/core/locales/it';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import axios from "axios"

window.Alpine = Alpine;

Alpine.start();

document.querySelectorAll(".sortable-column").forEach((item) => {
  item.addEventListener("click", (event) => {
    event.preventDefault();
    let order = item.dataset.sort;
    let dir = item.dataset.sortDir;
    let url = item.dataset.url;
    if (url.indexOf("?") < 1) {
      window.location.href = url + "?&order=" + order + "&order_dir=" + dir;
    } else {
      window.location.href = url + "&order=" + order + "&order_dir=" + dir;
    }
  });
});


document.addEventListener("DOMContentLoaded", function () {
  var calendarEl = document.getElementById("calendar");
  if(calendarEl){
    var calendar = new Calendar(calendarEl, {
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
        locale: itLocale,
        selectable: true,
        eventResizableFromStart: false,
        eventDurationEditable: false,
        eventStartEditable: false,
        headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek",
        },
        initialDate: "2024-01-01",
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        dayMaxEvents: true, // allow "more" link when too many events
        refetchResourcesOnNavigate: true,
        eventSources: {
            url: '/api/web-calendar',
            method: 'GET',
            extraParams: {
                calendar_id: calendar_id
            }
        },
        eventDrop: function(info) {
            let new_date = (info.event.start.getFullYear()+'-'+((info.event.start.getMonth()*1)+1)+'-'+info.event.start.getDate());
            axios.post('/api/update-collection', {
                calendar_id: calendar_id,
                collection_id: info.oldEvent.id,
                new_date: new_date,
            })
            .then((response) => {
                if(response.data.success){
                    calendar.refetchEvents();
                }else{
                    info.revert()
                }

            })
            .catch((error) => {
                info.revert()
            });


        }
    });

    calendar.render();
  }
});

